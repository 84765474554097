import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

 
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getUsers() {
      return this.http.get<any>(this.url + "/admin/user/");

  }
  getUserById(id){
    return this.http.get<any>(this.url + "/admin/user/getById/"+id);

  }
  getMembers() {
    return this.http.get<any>(this.url + "/admin/custom/");

  }

  register(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/user/register", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  updateFarmiles(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/user/updatFarmiles", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }

  getUserDetail(id) {
    return this.http.get<any>(this.url + "/admin/user/getByid?_id=" + id);
  }
  getUser() {
    return this.http.get<any>(this.url + "/admin/user/userProfile");
  }
  delete(id): any {
    return this.http.delete(this.url + "/admin/member/" + id);
  }
  deleteUser(id):any{
    return this.http.delete(this.url + "/admin/user/" + id);

  }
  getNotification(): any {
    return this.http.get(this.url + "/admin/notification/getAll");
  }
  getNotificationCount(): any {
    return this.http.get(this.url + "/admin/notification/getCount");
  }
  updateNotification(id) {    
    return this.http.put("admin/notification/update/"+id,{status:true});
  }
  getDocuments(user_id): any {
    return this.http.get(this.url + "/admin/document/getByUserId?_id="+user_id);
  }
  uploadDocument(body): Observable<HttpEvent<any>> {
    console.log("body",body)
    const formData: FormData = new FormData();
    formData.append("title", body.basicForm.name);
    formData.append("user_id", body.user_id);
    formData.append("file", body.file);
    const headers = new HttpHeaders();

    return this.http.post<any>(this.url + "/admin/document/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  deleteDocument(id){
    return this.http.delete(this.url + "/admin/document/" + id);

  }

}

