import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getBookings() {
    return this.http.get<any>(this.url + "/admin/booking/getCount");
  }
  // getBookingSearch(search,fromDate,toDate) {
  //   return this.http.get<any>(this.url + "/admin/booking/?search="+search+"&&from="+fromDate+"&&to="+toDate);
  // }

  getBookingSearch(search,fromDate,toDate){
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    const body:any = {}
    if(search){
      body.search = search 
    }
    if(fromDate){
      body.from = fromDate
    }
    if(toDate){
      body.to = toDate
    }
    console.log(body)
    return this.http.post<any>(this.url + "/admin/booking/getall",body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  getBookingsByUser(user_id) {
    return this.http.get<any>(this.url + "/admin/booking/getByUser?id="+user_id);

  }
  getBookingDetail(id) {
    return this.http.get<any>(this.url + "/admin/booking/getByid?_id=" + id);
  }
  getBookingsByPlan(id) {
    return this.http.get<any>(this.url + "/admin/booking/getByPlan?_id=" + id);
  }
  
  upload(body): Observable<HttpEvent<any>> {
    console.log("body",body)
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/booking/", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/booking/update", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  delete(id): any {
    return this.http.delete(this.url + "/admin/booking/" + id);
  }
}
