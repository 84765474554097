import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class BannerService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getBanners() {
    return this.http.get<any>(this.url + "/admin/banner/");
  }
  getBannerDetail(id) {
    return this.http.get<any>(this.url + "/admin/banner/getByid?_id="+ id);
  }
  upload(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("name", body.basicForm.name);
    formData.append("description", body.basicForm.description);
    formData.append("title", body.basicForm.title);
    formData.append("file", body.file);
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + "/admin/banner/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("id", body.id);
    formData.append("name", body.basicForm.name);
    formData.append("description", body.basicForm.description);
    formData.append("title", body.basicForm.title);
    formData.append("file", body.file);
    const headers = new HttpHeaders();

    return this.http.post<any>(this.url + "/admin/banner/update", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  delete(id): any {
    return this.http.delete(this.url + "/admin/banner/" + id);
  }
}
