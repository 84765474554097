import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { tap, switchMap, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import {
  NbAuthService,
  NbAuthToken,
  NB_AUTH_INTERCEPTOR_HEADER,
} from "@nebular/auth";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router:Router,
    @Inject(NB_AUTH_INTERCEPTOR_HEADER)
    protected headerName: string = "Authorization"
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      switchMap((token: NbAuthToken) => {
        if (token && token.getValue()) {
          req = req.clone({
            setHeaders: {
              [this.headerName]: "Bearer " + token.getValue(),
            },
          });
        }
        return next.handle(req).pipe(tap((evt) => {
        },(catchError)=>{
            if(catchError.status===401){                           
                localStorage.removeItem("auth_app_token");
                this.router.navigateByUrl('/');
            }
        }), );
      }), 
   
    );
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }
}
