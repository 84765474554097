import { LayoutService } from "./layout.service";
import { AnalyticsService } from "./analytics.service";
import { PlayerService } from "./player.service";
import { StateService } from "./state.service";
import { SeoService } from "./seo.service";
import { CustomValidatorsService } from "./custom-validators.service";
import { HotelService } from "./hotels/hotel.service";
import { BannerService } from "./banners/banner.service";
import { ApiService } from "./api/api.service";
import { PartnerService } from "./partners/partners.service";

import { MembershipService } from "./membership/membership.service";
import { FaqService } from "./faq/faq.service";
import { UsersService } from "./users/users.service";
import { AminitiesService } from "./aminities/aminities.service";
import { RoomTypesService } from "./rooms/room-types.service";
import { MemberRequestService } from "./member-requests/member-request.service";
import { PackagesService } from "./packages/packages.service";
import { PartnerRequestsService } from "./partner-requests/partner-requests.service";
import { ReviewsService } from "./reviews/reviews.service";
import { HouseboatsService } from "./houseboats/houseboats.service";
import { CmsService } from "./cms/cms.service";
import { OffersService } from "./offers/offers.service";
import { PartnerDetailService } from "./partner-details/partner-detail.service";
import { BecomeMemberService } from "./become_member/become-member.service";

export {
  LayoutService,
  AnalyticsService,
  PlayerService,
  SeoService,
  StateService,
  HotelService,
  BannerService,
  PartnerService,
  PartnerRequestsService,
  MembershipService,
  ApiService,
  FaqService,
  UsersService,
  AminitiesService,
  RoomTypesService,
  PackagesService,
  MemberRequestService,
  CustomValidatorsService,
  ReviewsService,
  HouseboatsService,
  CmsService,
  OffersService,
  PartnerDetailService,
  BecomeMemberService

};
