import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NbAuthJWTToken,
  NbAuthModule,
  NbDummyAuthStrategy,
  NbPasswordAuthStrategy,
} from "@nebular/auth";
import { NbSecurityModule, NbRoleProvider } from "@nebular/security";
import { of as observableOf } from "rxjs";

import { throwIfAlreadyLoaded } from "./module-import-guard";
import {
  AminitiesService,
  AnalyticsService,
  ApiService,
  BannerService,
  PartnerRequestsService,
  PartnerService,
  CustomValidatorsService,
  FaqService,
  HotelService,
  LayoutService,
  MembershipService,
  PlayerService,
  RoomTypesService,
  SeoService,
  StateService,
  UsersService,
  MemberRequestService,
  PackagesService,
  CmsService,
} from "./utils";

import { environment } from "../../environments/environment";
import { BookingService } from "./utils/booking/booking.service";
import { ReviewsService } from "./utils/reviews/reviews.service";
import { HouseboatsService } from "./utils/houseboats/houseboats.service";
import { OffersService } from "./utils/offers/offers.service";
import { PartnerDetailService } from "./utils/partner-details/partner-detail.service";
import { BecomeMemberService } from "./utils/become_member/become-member.service";

const socialLinks = [
  {
    url: "https://github.com/akveo/nebular",
    target: "_blank",
    icon: "github",
  },
  {
    url: "https://www.facebook.com/akveo/",
    target: "_blank",
    icon: "facebook",
  },
  {
    url: "https://twitter.com/akveo_inc",
    target: "_blank",
    icon: "twitter",
  },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf("guest");
  }
}

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: "email",
        token: {
          class: NbAuthJWTToken,

          key: "token", // this parameter tells where to look for the token
        },

        baseEndpoint: environment.apiBaseUrl + "/admin/",
        login: {
          // ...
          endpoint: "user/authenticate",
          redirect: {
            success: "/pages/dashboard",
            failure: null, // stay on the same page
          },
        },
        register: {
          // ...
          endpoint: "user/register",
          redirect: {
            success: "/pages/dashboard",
            failure: null, // stay on the same page
          },
        },
        logout: {
          endpoint: 'user/logout',
          method: 'get',
          redirect: {
              success: '/auth/login',
              failure: '/',
          },
        }
      }),
    ],
    forms: {
      login: {
        socialLinks: socialLinks,
      },
      register: {
        socialLinks: socialLinks,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: "*",
      },
      user: {
        parent: "guest",
        create: "*",
        edit: "*",
        remove: "*",
      },
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
  HotelService,
  MembershipService,
  ApiService,
  AminitiesService,
  RoomTypesService,
  BannerService,
  FaqService,
  UsersService,
  MemberRequestService,
  PackagesService,
  BookingService,
  PartnerService,
  PartnerRequestsService,
  CustomValidatorsService,
  ReviewsService,
  HouseboatsService,
  CmsService,
  OffersService,
  PartnerDetailService,
  BecomeMemberService
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
