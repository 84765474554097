import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PackagesService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getPackages() {
    return this.http.get<any>(this.url + "/admin/package/");
  }
  getPackageDetail(id) {
    return this.http.get<any>(this.url + "/admin/package/getByid?_id=" + id);
  }
  upload(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/package/", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post<any>(this.url + "/admin/package/update", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  delete(id): any {
    return this.http.delete(this.url + "/admin/package/" + id);
  }
}
