import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MemberRequestService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getRequests(body) {
    console.log("param",body);
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/member",body,{
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  getActiveMembers() {
    return this.http.get<any>(this.url + "/admin/member/active");
  }
  getMemberDetail(id) {
    return this.http.get<any>(this.url + "/admin/member/getByid?_id=" + id);
  }
  getCustomMemberDetail(id) {
    return this.http.get<any>(this.url + "/admin/custom/getByid?_id=" + id);
  }
  upload(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/member/", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post<any>(this.url + "/admin/member/update", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  delete(id): any {
    return this.http.delete(this.url + "/admin/member/" + id);
  }
  saveCustomPlan(body): Observable<HttpEvent<any>>{
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/custom/", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  updatePlan(body): Observable<HttpEvent<any>>{
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/custom/update", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  addPlan(body): Observable<HttpEvent<any>>{
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/custom/add-plan", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
}
