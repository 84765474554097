import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getOffers() {
    return this.http.get<any>(this.url + "/admin/offer/");
  }
  getOfferDetail(id) {
    return this.http.get<any>(this.url + "/admin/offer/getByid?_id="+ id);
  }
  upload(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("description", body.basicForm.description);
    formData.append("title", body.basicForm.title);
    formData.append("file", body.file);
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + "/admin/offer/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("id", body.id);
    formData.append("description", body.basicForm.description);
    formData.append("title", body.basicForm.title);
    formData.append("file", body.file);
    const headers = new HttpHeaders();

    return this.http.post<any>(this.url + "/admin/offer/update", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  delete(id): any {
    return this.http.delete(this.url + "/admin/offer/" + id);
  }
}
