import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
} from "@angular/core";
import { ApiService } from "../../../@core/utils";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "ngx-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
})
export class ImageUploadComponent implements OnInit, OnChanges{
  @Input() cover_image;
  @Input() cover_pic;
  @Output() cover_imageChange = new EventEmitter<string>();
  status=0;

  constructor(public apiService: ApiService,private domSanitizer: DomSanitizer) {
  }
  ngOnInit(){
    if(this.cover_pic) 
    console.log("cover",this.cover_image,this.cover_pic)

  }
 
  ngOnChanges() {
    if(this.cover_pic && !this.status) 
    {
      this.files[0]={
        name:this.cover_pic,
        url:this.apiService.url+'/static/uploads/'+this.cover_pic
      }
      this.cover_pic=this.apiService.url+'/static/uploads/'+this.cover_pic;
      this.status=1;

    }
    
}


  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    console.log("log");
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    console.log("this.files",this.files)
    if(this.files.length ){
      if (this.files[index].progress < 100) {
        console.log("Upload in progress.");
        return;
      }
      this.files.splice(index, 1);
      this.cover_image="";
      this.cover_imageChange.emit(this.cover_image);
      this.cover_pic="";
      console.log("this.files",this.files)

    }
  
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if(this.files.length){
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1);
            } else {
              this.files[index].progress += 5;
            }
          }
        
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      if (files && files[0]) {
        const file = files[0];
        this.cover_image = file;
        // this.files.push(item)
        this.files[0] = item;
        const reader = new FileReader();
        // reader.onload = e => this.profile_pic = reader.result as string;
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.cover_pic= this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());
  
        };
        this.cover_imageChange.emit(file);
      }
    }
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
