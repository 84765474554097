import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public readonly url: string = environment.apiBaseUrl;
  constructor() {}
}
