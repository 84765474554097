import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getReviews() {
    return this.http.get<any>(this.url + "/admin/review");
  }
  getReviewDetail(id) {
    return this.http.get<any>(this.url + "/admin/review/getByid?_id=" + id);
  }

  upload(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
   
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("reviewed_date", body.reviewed_date);
    formData.append("star_rating", body.star_rating);
    formData.append("user", body.user);

    formData.append("file", body.file);
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url +"/admin/review/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append("id", body.id);
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("reviewed_date", body.reviewed_date);
    formData.append("star_rating", body.star_rating);
    formData.append("user", body.user);
    formData.append("file", body.file);

    const headers = new HttpHeaders();

    return this.http.post<any>(this.url + "/admin/review/update", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
 
  delete(id): any {
    return this.http.delete(this.url + "/admin/review/" + id);
  }
}
