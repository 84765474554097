import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HouseboatsService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getresorts() {
    return this.http.get<any>(this.url + "/admin/resort/");
  }
  upload(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("name", body.basicForm.name);
    formData.append("description", body.basicForm.description);
    formData.append("location", body.basicForm.location);
    formData.append("featured", body.basicForm.featured);
    formData.append("popular", body.basicForm.popular);
    formData.append("type", body.basicForm.type);
    formData.append("hotel_id", body.basicForm.hotel_id);
    formData.append("file", body.file);
    formData.append("aminities", JSON.stringify(body.aminities));
    formData.append("destination", body.basicForm.destination);

    formData.append("star_rating", body.star_rating);
    formData.append("latitude", body.latitude);
    formData.append("longitude", body.longitude);
    formData.append("address_components",   JSON.stringify(body.address_components));
    formData.append("property_type", "houseboat");

    const headers = new HttpHeaders();

    return this.http.post<any>(this.url + "/admin/hotel/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
}
