
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PartnerRequestsService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getPartnerRequests() {
    return this.http.get<any>(this.url + "/admin/partner-request/");
  }
 
  delete(id): any {
    return this.http.delete(this.url + "/admin/partner-request/" + id);
  }
}

