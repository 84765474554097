import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HotelService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  gethotels() {
    return this.http.get<any>(this.url + "/admin/hotel/all");
  }
  getHotelDetail(id) {
    return this.http.get<any>(this.url + "/admin/hotel/getByid?_id=" + id);
  }
  upload(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("name", body.basicForm.name);
    formData.append("destination",  body.basicForm.destination);
    formData.append("aminities", JSON.stringify(body.aminities));
    formData.append("description", body.basicForm.description);
    formData.append("location", body.basicForm.location);
    formData.append("featured", body.basicForm.featured);
    formData.append("popular", body.basicForm.popular);
    formData.append("type", body.basicForm.type);
    formData.append("hotel_id", body.basicForm.hotel_id);
    formData.append("file", body.file);
    formData.append("star_rating", body.star_rating);
    formData.append("latitude", body.latitude);
    formData.append("longitude", body.longitude);
    formData.append("address_components",   JSON.stringify(body.address_components));
    formData.append("property_type",'hotel');

    //   body.fields.forEach((item) =>{
    //     console.log("item,,,",item)
    //     formData.append("fields["+item.field+"]", item.value);

    //   });
    //   for (var i = 0; i < body.documents.length; i++) {
    //     formData.append(body.documents[i].slug, body.documents[i].file, body.documents[i].slug );
    //  }
    //  console.log("formData",formData);

    const headers = new HttpHeaders();
    // const headers = new HttpHeaders({ "Content-Type": "application/json" });

    // return this.http.post<any>(this.url + "/admin/hotel/", body, {
    //   responseType: "json",
    //   headers,
    //   withCredentials: false,
    // });

    return this.http.post<any>(this.url + "/admin/hotel/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("id", body.id);
    formData.append("name", body.basicForm.name);
    formData.append("description", body.basicForm.description);
    formData.append("star_rating", body.star_rating);
    formData.append("latitude", body.latitude);
    formData.append("longitude", body.longitude);
    formData.append("address_components",   JSON.stringify(body.address_components));
    formData.append("location", body.basicForm.location);
    formData.append("featured", body.basicForm.featured);
    formData.append("popular", body.basicForm.popular);
    formData.append("type", body.basicForm.type);
    formData.append("hotel_id", body.basicForm.hotel_id);
    formData.append("aminities",  JSON.stringify(body.aminities));
    formData.append("destination",  body.basicForm.destination);
    formData.append("property_type", body.basicForm.property_type);

    formData.append("file", body.file);
    const headers = new HttpHeaders();

    return this.http.post<any>(this.url + "/admin/hotel/update", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }

  delete(id): any {
    return this.http.delete(this.url + "/admin/hotel/" + id);
  }
  getImages() {
    return this.http.get<any>(this.url + "/admin/hotelimage/");
  }
  getImagesByHotelId(id) {
    return this.http.get<any>(
      this.url + "/admin/hotelimage/getByHotelId?_id=" + id
    );
  }
  getImageDetail(id) {
    return this.http.get<any>(this.url + "/admin/hotelimage/getByid?_id=" + id);
  }
  uploadImage(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    console.log("data", body);
    formData.append("name", body.basicForm.name);
    formData.append("hotel_id", body.hotel_id);

    formData.append("file", body.file);
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + "/admin/hotelimage/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  updateImage(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("id", body.id);
    formData.append("hotel_id", body.hotel_id);
    formData.append("name", body.basicForm.name);
    formData.append("file", body.file);
    const headers = new HttpHeaders();

    return this.http.post<any>(
      this.url + "/admin/hotelimage/update",
      formData,
      {
        responseType: "json",
        headers,
        withCredentials: false,
      }
    );
  }
  deleteImage(id): any {
    return this.http.delete(this.url + "/admin/hotelimage/" + id);
  }
  getRooms() {
    return this.http.get<any>(this.url + "/admin/room/");
  }
  getAllRooms() {
    return this.http.get<any>(this.url + "/admin/room/all");
  }
  getRoomDetail(id) {
    return this.http.get<any>(this.url + "/admin/room/getByid?_id=" + id);
  }
  getRoomsByHotelId(id) {
    return this.http.get<any>(this.url + "/admin/room/getByHotelId?_id=" + id);
  }
  uploadRoom(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    console.log("data", body);
    formData.append("name", body.basicForm.name);
    formData.append("hotel_id", body.hotel_id);
    formData.append("roomtype_id", body.basicForm.type._id);
    formData.append("price", body.basicForm.price);
    formData.append("discount_price", body.basicForm.discount_price);
    formData.append("adults", body.basicForm.adults);
    formData.append("children", body.basicForm.children);
    for(let file in body.file) {
      formData.append("file", body.file[file]);
    }
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + "/admin/room/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  updateRoom(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("id", body.id);
    formData.append("name", body.basicForm.name);
    formData.append("price", body.basicForm.price);
    formData.append("discount_price", body.basicForm.discount_price);

    formData.append("adults", body.basicForm.adults);
    formData.append("children", body.basicForm.children);
    formData.append("roomtype_id", body.basicForm.type._id);
    formData.append("other_images",  JSON.stringify(body.other_images));

    for(let file in body.file) {
      formData.append("file", body.file[file]);
    }    const headers = new HttpHeaders();

    return this.http.post<any>(this.url + "/admin/room/update", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  deleteRoom(id): any {
    return this.http.delete(this.url + "/admin/room/" + id);
  }
  getNonMemberProperties(id,param){
    console.log("params",param)
    return this.http.get(this.url + "/admin/room/getNonMemberProperties?_id=" + id +'&search='+ param);

  }
  deletebookig(id){

    return this.http.delete(this.url + "/admin/booking/" + id);

  }
  getNotes() {
    return this.http.get<any>(this.url + "/admin/notes/");
  }
  getNoteDetail(id) {
    return this.http.get<any>(this.url + "/admin/notes/getByid?_id=" + id);
  }
  getNotesByHotelId(id) {
    return this.http.get<any>(this.url + "/admin/notes/getByHotelId?_id=" + id);
  }
  changeStatus(body): Observable<HttpEvent<any>> {
    console.log("aaaa",body)
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/hotel/changeStatus", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }

  uploadNotes(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    console.log("data", body);
    formData.append("title", body.basicForm.title);
    formData.append("hotel_id", body.hotel_id);
    formData.append("description", body.basicForm.description);
    formData.append("file", body.file);

    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + "/admin/notes/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  updateNotes(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("id", body.id);
    formData.append("title", body.basicForm.title);
    formData.append("description", body.basicForm.description);  
    
      formData.append("file", body.file);
        const headers = new HttpHeaders();

    return this.http.post<any>(this.url + "/admin/notes/update", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  deleteNote(id): any {
    return this.http.delete(this.url + "/admin/notes/" + id);
  }
  getCancellationPolicy(id): any {
    return this.http.get(this.url + "/admin/hotel/getPolicy?_id=" + id);
  }
  updatePolicy(body){
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/hotel/updatePolicy", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
}
