import {
  ValidatorFn,
  AbstractControl,
  FormControl,
  Validators,
} from "@angular/forms";

export class CustomValidatorsService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: "Required",
      minlength: `Minimum length ${validatorValue.requiredLength}.`,
      maxlength: `Maxium length ${validatorValue.requiredLength}.`,
      integer: `Invalid integer value.`,
      numeric: `Invalid number.`,
      min: `The value should be greater than ${validatorValue}`,
      max: `The value should be less than ${validatorValue}`,
      range: `The value should be between ${validatorValue[0]} and ${validatorValue[1]}.`,
      dollar: `Invalid currency format.`,
    };

    return config[validatorName];
  }

  static integer(): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } => {
      const val = control.value;

      if (isNaN(Number.parseInt(val)) || !/^(0|[1-9]\d*)$/.test(val)) {
        return { integer: true };
      }
    };
  }

  static numeric(): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } => {
      const val = control.value;

      if (isNaN(parseFloat(val)) || !/^(0|[1-9]\d*)(\.\d+)?$/.test(val)) {
        return { numeric: true };
      }
    };
  }

  static dollar(): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } => {
      const val = control.value;
      if (isNaN(parseFloat(val)) || !/^(0|[1-9]\d*)(\.\d{1,2})?$/.test(val)) {
        return { dollar: true };
      }
    };
  }

  static min(min: number): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      const input = control.value,
        isValid = input < min;
      if (isValid) {
        return { min: min };
      }
    };
  }

  static max(max: Number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value,
        isValid = input > max;
      if (isValid) {
        return { max: max };
      }
    };
  }
  static postalCodeValidator(control) {
    if (control.value.match(/^\d{6}$/)) {
      return null;
    } else {
      return false;
    }
  }

  static range(min: Number, max: Number, inclusive?: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;

      if (inclusive) {
        if (input < min || input > max) {
          return { range: [min, max] };
        }
      } else {
        if (input <= min || input >= max) {
          return { range: [min, max] };
        }
      }
    };
  }
}
