<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()">Farstay</a>
    </div>
    <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>
</div>

<div class="header-container">
    <nb-actions size="small">
        <!-- <nb-action class="control-item">
            <nb-search type="rotate-layout"></nb-search>
        </nb-action> -->
        <nb-action class="control-item" icon="email-outline"></nb-action>
        <nb-action *ngIf="notifications" link="/pages/notifications"  class="control-item bell" icon="bell-outline" [badgeText]="notifications+'+'" badgeStatus="success"
        badgePosition="top">        </nb-action>

        <nb-action *ngIf="!notifications" link="/pages/notifications"  class="control-item bell" icon="bell-outline" >
            

        </nb-action>
        <nb-badge text="new" status="success" position="top right"></nb-badge>
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
            <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.profile_pic">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>

