<div class="dropzone" ngxDnd (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)">
    <img src="assets/images/dnd/ic-upload-file.svg">
    <h3>Drag and Drop file here</h3>
    <h3>or</h3>
    <label for="fileDropRef">Browse for file</label>
</div>

<div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
        <!-- <img src="assets/images/dnd/ic-file.svg" width="45px" alt="file"> -->
        <img [src]="cover_pic" width="45px" alt="file">
        <div class="info">
            <h4 class="name">
                {{ file?.name }}
            </h4>
            <p class="size" *ngIf="file.hasOwnProperty('size')">
                {{ formatBytes(file?.size) }}
            </p>
            <img src="" alt="">
            <ngx-progress *ngIf="file.hasOwnProperty('progress')" [progress]="file?.progress"></ngx-progress>
        </div>
        <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
    </div>
</div>