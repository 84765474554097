import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getCms() {
    return this.http.get<any>(this.url + "/admin/cms/");
  }
  getCmsDetail(id) {
    return this.http.get<any>(this.url + "/admin/cms/getByid?_id=" + id);
  }

  update(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append("id", body.basicForm.id);
    formData.append("title", body.basicForm.title);
    formData.append("description", body.basicForm.description);
    formData.append("alternate_description", body.basicForm.alternate_description);
    formData.append("file", body.file);
    const headers = new HttpHeaders();

    return this.http.post<any>(this.url + "/admin/cms/update", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
 
}
