import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RoomTypesService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getRoomTypes() {
    return this.http.get<any>(this.url + "/admin/roomtype/");
  }
  getRoomType(id) {
    return this.http.get<any>(this.url + "/admin/roomtype/getByid?_id=" + id);
  }
  upload(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/roomtype/", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post<any>(this.url + "/admin/roomtype/update", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  delete(id): any {
    return this.http.delete(this.url + "/admin/roomtype/" + id);
  }
}
