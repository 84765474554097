import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Observable, of } from 'rxjs';
import { tap, startWith, map } from 'rxjs/operators';
@Injectable({
  providedIn: "root",
})
export class MembershipService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getMemberships() {
    return this.http.get<any>(this.url + "/admin/membership/");
  }
  getMembershipDetail(id) {
    return this.http.get<any>(this.url + "/admin/membership/getByid?_id=" + id);
  }
  upload(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log("data", body);
    formData.append("name", body.basicForm.name);
    formData.append("type", body.basicForm.type);
    formData.append("validity", body.basicForm.validity);
    formData.append("price", body.basicForm.price);
    formData.append("farmiles", body.basicForm.farmiles);
    formData.append("adults", body.basicForm.adults);
    formData.append("children", body.basicForm.children);
    
    formData.append("voucher", body.basicForm.voucher);
    formData.append("fuel_cashback", body.basicForm.cashback);
    formData.append("international_vocher", body.basicForm.internationalvoucher);
    
    formData.append("overview", body.basicForm.overview);
    formData.append("benefits", JSON.stringify(body.benefits));
    formData.append("activities", JSON.stringify(body.activities));
    formData.append("properties", JSON.stringify(body.properties));
    formData.append("stays", JSON.stringify(body.stays));

    formData.append("overview_image", body.overview_image);
    formData.append("activities_image", body.activities_image);

    formData.append("cover_pic", body.file);
    console.log("formdata",formData);
    const headers = new HttpHeaders();
    return this.http.post<any>(this.url + "/admin/membership/", formData, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    console.log("data",  body.basicForm.internationalvoucher);
    formData.append("id", body.id);
    formData.append("name", body.basicForm.name);
    formData.append("type", body.basicForm.type);
    formData.append("validity", body.basicForm.validity);
    formData.append("price", body.basicForm.price);
    formData.append("farmiles", body.basicForm.farmiles);
    formData.append("voucher", body.basicForm.voucher);
    formData.append("international_vocher", body.basicForm.internationalvoucher);
    formData.append("fuel_cashback", body.basicForm.cashback);
    formData.append("adults", body.basicForm.adults);
    formData.append("children", body.basicForm.children);
    formData.append("overview", body.basicForm.overview);
    formData.append("benefits", JSON.stringify(body.benefits));
    formData.append("activities", JSON.stringify(body.activities));
    formData.append("stays", JSON.stringify(body.stays));
    formData.append("properties", JSON.stringify(body.properties));


    formData.append("overview_image", body.overview_image);
    formData.append("activities_image", body.activities_image);

    formData.append("cover_pic", body.file);
    console.log("formdata",formData);

    const headers = new HttpHeaders();

    return this.http.post<any>(
      this.url + "/admin/membership/update",
      formData,
      {
        responseType: "json",
        headers,
        withCredentials: false,
      }
    );
  }
  delete(id): any {
    return this.http.delete(this.url + "/admin/membership/" + id);
  }
  cities = [];
  countries = [];

  getCountries() {
    return this.http.get<any>(this.url + "/admin/member/getCountries");
  }

  getCities(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/member/getCities", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
    // return  this.http.post('admin/member/getCities',body).pipe(
    //     map((data) => {
    //       console.log("data",data)
    //       this.cities = data['cities'];
    //       return this.cities;
    //     })
    //   )
  
}
