import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerDetailService {
  private url = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}
  getPartnerDetails() {
    return this.http.get<any>(this.url + "/admin/partner-detail/");
  }
  getDetail(id) {
    return this.http.get<any>(this.url + "/admin/partner-detail/getByid?_id=" + id);
  }
  upload(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post<any>(this.url + "/admin/partner-detail/", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  update(body): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post<any>(this.url + "/admin/partner-detail/update", body, {
      responseType: "json",
      headers,
      withCredentials: false,
    });
  }
  delete(id): any {
    return this.http.delete(this.url + "/admin/partner-detail/" + id);
  }
}
